import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LoyaltyListPage from "./LoyaltyListPage";

export default function LoyaltyPage() {
  const navigate = useNavigate();
  const initialResourceProps = {
    Authorization: "",
    GTM_ID: "",
    "X-Client-Id": "efisheryku",
  };
  const [resourceProps, setResourceProps] = useState(initialResourceProps);

  useEffect(() => {
    const authorizationToken = sessionStorage.getItem("dev_loyalty_auth");
    const xClientId = sessionStorage.getItem("dev_loyalty_clientid");
    const gtmId = sessionStorage.getItem("dev_loyalty_gtmid");
    setResourceProps((prev) => ({
      ...prev,
      ...(authorizationToken && { Authorization: authorizationToken }),
      ...(xClientId && { "X-Client-Id": xClientId }),
      ...(gtmId && { GTM_ID: gtmId }),
    }));
  }, []);

  return (
    <LoyaltyListPage
      resourceProps={{
        gtmID: resourceProps.GTM_ID,
        meta: {
          headers: {
            Authorization: resourceProps.Authorization,
            "X-Client-Id": resourceProps["X-Client-Id"],
          },
        },
      }}
      handleBack={() => navigate(`/`)}
      goToBeliPakan={() => navigate(`/beli-pakan`)}
      useFragmentWrapper
      useOuterContainer
    />
  );
}

